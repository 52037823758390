.captcha_container{
	@include display-flex;
	@include flex-wrap(wrap);
	@include flex-justify(center);

	background-color: lightgray;
	border: 1px solid #b7b7b7;
	border-radius: 4px;
	margin-top: 20px;

	.captcha_input{
		background-color: white;

		margin: 5px;
		padding: 0px;

		border: 0px;
		border-radius: 4px;

		height: 45px;
		padding: 0px 30px;
		font-size: 30px;
	}

	.captcha_image_container{
		margin: 0px 5px 5px 5px;
		width: 100%;
		text-align: center;
	}
}