//*****************************************************************************//
//**************************** GLOBAL VARIABLES *******************************//
//*****************************************************************************//
@import "variables";
//******************************************************************//
//**************************** FONTS *******************************//
//******************************************************************//
/*!
 * @license
 * MyFonts Webfont Build ID 3795124, 2019-08-09T04:02:54-0400
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: CaeciliaLTPro-76BoldItalic by Monotype
 * URL: https://www.myfonts.com/fonts/mti/pmn-caecilia/pro-76-bold-italic-190917/
 *
 * Webfont: CaeciliaLTPro-55Roman by Monotype
 * URL: https://www.myfonts.com/fonts/mti/pmn-caecilia/pro-55-roman-190917/
 *
 * Webfont: CaeciliaLTPro-56Italic by Monotype
 * URL: https://www.myfonts.com/fonts/mti/pmn-caecilia/pro-56-italic-190917/
 *
 * Webfont: CaeciliaLTPro-46LightItalic by Monotype
 * URL: https://www.myfonts.com/fonts/mti/pmn-caecilia/pro-46-light-italic-190917/
 *
 * Webfont: CaeciliaLTPro-45Light by Monotype
 * URL: https://www.myfonts.com/fonts/mti/pmn-caecilia/pro-45-light-190917/
 *
 * Webfont: CaeciliaLTPro-75Bold by Monotype
 * URL: https://www.myfonts.com/fonts/mti/pmn-caecilia/pro-75-bold-190917/
 *
 *
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3795124
 * Licensed pageviews: 2,500,000
 * Webfonts copyright: Copyright &#x00A9; 2015 - 2019 Monotype Imaging Inc. All rights reserved.
 *
 * © 2019 MyFonts Inc
*/
/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/39e8b4");

@font-face {
	font-family: 'CaeciliaLTPro';
	font-style: italic;
	font-weight: bold;
	src: url($basepath_font + 'CaeciliaLTPro/39E8B4_0_0.eot');
	src: url($basepath_font + 'CaeciliaLTPro/39E8B4_0_0.eot?#iefix') format('embedded-opentype'), url($basepath_font + 'CaeciliaLTPro/39E8B4_0_0.woff2') format('woff2'), url($basepath_font + 'CaeciliaLTPro/39E8B4_0_0.woff') format('woff'), url($basepath_font + 'CaeciliaLTPro/39E8B4_0_0.ttf') format('truetype'), url($basepath_font + 'CaeciliaLTPro/39E8B4_0_0.svg#wf') format('svg');
}

@font-face {
	font-family: 'CaeciliaLTPro';
	src: url($basepath_font + 'CaeciliaLTPro/39E8B4_1_0.eot');
	src: url($basepath_font + 'CaeciliaLTPro/39E8B4_1_0.eot?#iefix') format('embedded-opentype'), url($basepath_font + 'CaeciliaLTPro/39E8B4_1_0.woff2') format('woff2'), url($basepath_font + 'CaeciliaLTPro/39E8B4_1_0.woff') format('woff'), url($basepath_font + 'CaeciliaLTPro/39E8B4_1_0.ttf') format('truetype'), url($basepath_font + 'CaeciliaLTPro/39E8B4_1_0.svg#wf') format('svg');
}

@font-face {
	font-family: 'CaeciliaLTPro';
	font-style: italic;
	src: url($basepath_font + 'CaeciliaLTPro/39E8B4_2_0.eot');
	src: url($basepath_font + 'CaeciliaLTPro/39E8B4_2_0.eot?#iefix') format('embedded-opentype'), url($basepath_font + 'CaeciliaLTPro/39E8B4_2_0.woff2') format('woff2'), url($basepath_font + 'CaeciliaLTPro/39E8B4_2_0.woff') format('woff'), url($basepath_font + 'CaeciliaLTPro/39E8B4_2_0.ttf') format('truetype'), url($basepath_font + 'CaeciliaLTPro/39E8B4_2_0.svg#wf') format('svg');
}

@font-face {
	font-family: 'CaeciliaLTPro';
	font-weight: 200;
	font-style: italic;
	src: url($basepath_font + 'CaeciliaLTPro/39E8B4_3_0.eot');
	src: url($basepath_font + 'CaeciliaLTPro/39E8B4_3_0.eot?#iefix') format('embedded-opentype'), url($basepath_font + 'CaeciliaLTPro/39E8B4_3_0.woff2') format('woff2'), url($basepath_font + 'CaeciliaLTPro/39E8B4_3_0.woff') format('woff'), url($basepath_font + 'CaeciliaLTPro/39E8B4_3_0.ttf') format('truetype'), url($basepath_font + 'CaeciliaLTPro/39E8B4_3_0.svg#wf') format('svg');
}

@font-face {
	font-family: 'CaeciliaLTPro';
	font-weight: 200;
	src: url($basepath_font + 'CaeciliaLTPro/39E8B4_4_0.eot');
	src: url($basepath_font + 'CaeciliaLTPro/39E8B4_4_0.eot?#iefix') format('embedded-opentype'), url($basepath_font + 'CaeciliaLTPro/39E8B4_4_0.woff2') format('woff2'), url($basepath_font + 'CaeciliaLTPro/39E8B4_4_0.woff') format('woff'), url($basepath_font + 'CaeciliaLTPro/39E8B4_4_0.ttf') format('truetype'), url($basepath_font + 'CaeciliaLTPro/39E8B4_4_0.svg#wf') format('svg');
}

@font-face {
	font-family: 'CaeciliaLTPro';
	font-weight: bold;
	src: url($basepath_font + 'CaeciliaLTPro/39E8B4_5_0.eot');
	src: url($basepath_font + 'CaeciliaLTPro/39E8B4_5_0.eot?#iefix') format('embedded-opentype'), url($basepath_font + 'CaeciliaLTPro/39E8B4_5_0.woff2') format('woff2'), url($basepath_font + 'CaeciliaLTPro/39E8B4_5_0.woff') format('woff'), url($basepath_font + 'CaeciliaLTPro/39E8B4_5_0.ttf') format('truetype'), url($basepath_font + 'CaeciliaLTPro/39E8B4_5_0.svg#wf') format('svg');
}

//************************ Riegel Web Font ************************//
@font-face {
	font-family: "riegel-icons-web-regular";
	src: url("#{$basepath_font}/riegel-icons-web-regular/riegel-icons-web-regular-webfont.woff") format("woff"),
	url("#{$basepath_font}/riegel-icons-web-regular/riegel-icons-web-regular-webfont.woff2") format("woff2");
}

.fs-riegel-icons-web-regular {
	font-family: "riegel-icons-web-regular", sans-serif;
}

@font-face {
	font-family: "riegel-icons-print";
	src: url("#{$basepath_font}/riegel-icons-print/riegel-icons-print-webfont.woff") format("woff"),
	url("#{$basepath_font}/riegel-icons-print/riegel-icons-print-webfont.woff2") format("woff2");
}

//******************************************************************//
//************************ Foundation Stuff ************************//
//******************************************************************//
@import "../libraries/foundation/scss/foundation.scss";
@include foundation-everything(true, false);

//********************************************************************//
//************************ Font Awesome Stuff ************************//
//********************************************************************//
@import "../libraries/font_awesome/scss/fontawesome.scss";
@import "../libraries/font_awesome/scss/solid.scss";
@import "../libraries/font_awesome/scss/regular.scss";
@import "../libraries/font_awesome/scss/brands.scss";

//******************************************************************//
//*************************** MODULES CSS **************************//
//******************************************************************//
@import "../modules/css/all_libs";
@import "../modules/css/all_modules";

//**************************************************************//
//************************ LAYOUT STUFF ************************//
//**************************************************************//
html,
body {
	font-family: $font_family_text;
	font-size: 12px;
	width: 100%;
	height: 100%;
	color: $body-font-color;
	overflow: visible!important;
}

body {
	a {
		color: $primary-color;
	}
}
#main{
	overflow-x: hidden;
}

.fab,
.fas,
.far {
	font-style: normal;
}

.button {
	background-color: $primary-color;
}

.button.primary {
	background-color: $primary-color;
	border-radius: 0px;
/*
	font-size: 15px;
	padding: 9px;
*/
	&:hover {
		background-color: $secondary-color;
	}
}

.button.primary,
.button.secondary {
	color: white;

	.button_icon {
		margin-right: 10px;
	}

	&:hover {
		color: white;
	}
}

//**************************************************************//
//*************************** HEADER ***************************//
//**************************************************************//
#mobilmenu{
	font-family: "CaeciliaLTPro", Times, sans-serif;
	a.invisible{
		display: none;
	}
	.mm-navbar__title{
		display: none;
	}
	.mm-navbar {
		background:#fff;
		border-bottom:1px dotted #ddd;
		.mobilheadericons{
			margin-top: 15px;
			.mobil-icons{
				width: 25%;
				.fs-riegel-icons-web-regular{
					font-size: 32px;
					line-height: 16px;
					color:#706F6F!important;
				}
				span{
					font-size: 10px;
					color:#706F6F!important;
				}
				.tooltip{
					background-color:#706F6F;
					border-radius: 0;
					color:#fff!important;
				}
			}
		}
	}
	.mm-panel{
		background-color: #fff;
		ul{
			li{
				background-color: #f8f7f7;
				border: 0px solid transparent;
				margin-bottom: 2px;
				&.active{
					background-color:#fee6f1;
				}
				a{
					color: #86214f;
					font-size: 16px;
					&::after{
						border-width: 1px;
						border-color: #86214f;
						margin-top: 22px;
					}
				}
				.mm-listitem__btn:not(.mm-listitem__text){
					border:none;
				}
				&.mm-listitem_opened{
					a{
						background-color: transparent;
					}
				}
				.mm-panel{
					ul{
						li{
							background-color: transparent;
							a{
								padding-top: 0;
								padding-bottom:0;
								font-family: $font_family_text;
								color: #474747;
								font-size: 12px;
								line-height: 20px;
								strong{
									color:#86214f;
								}
							}
						}
					}
				}
			}
			&.level_2 {
				li {
					a {
						height:26px;
						&::after {
							margin-top:0;
						}
					}
				}
			}
		}
	}
}

#search_output[data-ez-open="true"]{
	display: block!important;
}

#wrapper {
	@include display-flex;
	@include flex-direction(column);
	min-height: 100%;
	background-color: #FFFFFF;
	padding-top: 60px;
	@media (min-width: 1024px) {
		padding-top: $first-header-height+$second-header-height;
		transition: padding-top 0.5s;
	}

	body.with-breadcrumbs & {
		padding-top: 60px;
		@media (min-width: 1024px) {
			padding-top: $first-header-height+$second-header-height+26px;
			transition: padding-top 0.5s;
		}
	}

	#header {
		position: fixed;
		z-index: 10;
		top: 0;
		left: 0;
		right: 0;
		width: 100%;
		transition: right 0.5s ease!important;
	}

	.head-spacer {
		border: none;
		margin-top: 130px;
	}

	.show{
		display: block !important;
	}

	#header_wrap {
		#header_1_container {
			position: relative;
			height: 60px;
			//margin-bottom: $second-header-height;
			background: rgb(137, 34, 81);
			background: linear-gradient(90deg, rgba(137, 34, 81, 1) 0%, rgba(194, 90, 139, 1) 50%, rgba(137, 34, 81, 1) 100%);
			font-family: CaeciliaLTPro, sans-serif;

            @media (min-width: 1024px) {
				height: $first-header-height;
				margin-bottom: $second-header-height;
			}

            #header_1{
                height: 100%;
                margin-left: auto!important;
                margin-right: auto!important;
                max-width: $global-width;
                font-size: 11px;
                flex-flow: row!important;

                & > .columns{
                    flex: unset;
                    padding: 0 15px;
                    height: 100%;

                    &.search{
                        flex-grow: 3;
                        padding: 0;

                        .search_container {
                            align-self:start;
                            height:60px;
                            width: 100%;
                            input#search_open,
                            label.search_open {
                                display:none;
                            }

                            #header_search {
                                position: relative;
                                @include display-flex;
                                @include flex-align(start);
                                padding: 0;
                                height: 100%;
                                width: 100%;

                                #search_container {
                                    position: relative;
                                    margin-top:7px;
                                    padding: 0;
                                    width: 100%;
                                    height: 30px;
                                    justify-content: center;

                                    @media (max-width: 64rem) {
                                        margin-top:18px;
                                    }
                                    .search_open,
                                    .search_open_hidden {
                                        display:none;
                                    }

                                    button{
                                        span{
                                            border:none;
                                        }
                                    }

                                    form {
                                        @include display-flex;
                                        width:100%;
										#search_input_container {
											display: flex;
											width: 100%;
										}
                                    }

                                    &:hover #search_input,
                                    &:hover .search {
                                        border-color:#52626b;
                                    }

                                    #search_input{
                                        height: 25px;
                                        border:none;
                                        transition: width 0.6s ease, background-color 0.6s ease;
                                        background-color: #fff;
                                        width: calc(100% - 25px);

                                        @media (min-width: 640px) {
                                            width: calc(100% - 35px);
                                        }
                                    }

                                    button{
                                        position: absolute;
                                        right: -10px;
                                        color:#fff;
                                        transition: color 0.3s ease;
                                        @media (min-width: 640px) {
                                            right:0px;
                                        }
                                        &:hover{
                                            color:#f29fc5!important;
                                            transition: color 0.3s ease;
                                        }
                                        .suchetext{
                                            display: none;
                                        }
                                    }

                                    @media (min-width: 1024px) {
                                        #search_input{
                                            right: 75px;
                                        }
                                        button{
                                            color:#fff;
                                            transition: color 0.3s ease;
                                            span.suchetext{
                                                cursor: pointer;
                                                position: relative;
                                                right: 0px;
                                            }
                                            .search{
                                                position: relative;
                                            }
                                            &:hover{
                                                color:#f29fc5!important;
                                                transition: color 0.3s ease;
                                            }
                                        }
                                    }
                                }

                                input#search_input {
                                    margin: 0;
                                    padding-top:10px;
                                    height: 30px;
                                    border:none;
                                    border-radius: 0;
                                    box-shadow: none;
                                    font-size: 15px;
                                    color:#706f6f;
                                    background-color:transparent;
                                    &::placeholder {
                                        color: #706f6f;
                                        opacity: 0.5;
                                    }
                                }

                                .search {
                                    display: inline-block;
                                    margin:0;
                                    padding: 0;
                                    height:30px;
                                    width:30px;
                                    vertical-align: middle;
                                    border: 1px solid #C8C8C8;
                                    border-left:none;
                                    font-family: "riegel-icons-web-regular";
                                    font-size: 35px;
                                    line-height: 30px;
                                    transition: border-color .25s ease-out;
                                    text-align: center;
                                    cursor: pointer;
                                    @media (min-width: 640px) {
                                        font-size: 27px;
                                    }
                                }

                                #search_output {
                                    top: 40px;
									max-height: 85vh;
									overflow: scroll;
									@media screen and ( max-width:640px ) {
										top: 60px;
										box-shadow: 0px 3px 16px 0px black;
										width: 95vw;
										margin: 0 auto;
									}

                                    left: 0;
                                    margin:0;
                                    padding: 15px;
									display: none;
									min-width: inherit;
                                    &::before {
                                        left: auto;
                                        right: 40px;
                                    }
									li {
										padding:0;
										h2 {padding:0.4rem;}
										a {
											display:block;
											margin:0;
											padding:0 0.4rem 0.4rem 0.4rem;
										}
										.search_item_spacer {
											margin: 0;
										}

										.search_picture {
											max-width: 80px;
										}
									}
                                    .search_headline {border-bottom:none;}
                                    li.search_item {
                                        font-size:0.8rem;
                                        img.search_picture {max-height:80px;}
                                        h3 {margin-bottom:0.3rem;color:$primary-color;}
                                        p.artnr {margin:0;font-size:0.7rem;color:black;}
                                        .callout {
                                            margin-bottom:0.4rem;
                                            padding:0.4rem;
                                            a {
                                                padding:0;
                                                div {
                                                    margin:0;
                                                    &:first-child {
                                                        display:flex;
														@media screen and (max-width:400px) {
															display: block;
														}
                                                        img.search_picture {align-self:center;}
                                                    }
                                                }
                                            }
                                        }
                                    }
									.ez-flex-container {
										display: flex;
										margin-bottom: 8px;
										border-bottom: 1px solid;
										padding-bottom: 12px;
										flex-wrap: wrap;
										.search_item {
											&:last-of-type a {
												margin-right: 0 !important;
											}
											a {
												padding: 5px 8px;
												margin: 0 8px 8px 0;
												border: 1px solid #832555;
												border-radius: 8px;
												font-size: 1rem;
												transition: .3s all;
												&:hover {
													background-color: #832555;
													color: white;
												}
											}
										}
									}
									#ezCloseSearchOutput {
										position: absolute;
										top: 0;
										right: 0;
										width: 40px;
										height: 40px;
										transition: .3s all;
										&:hover {
											cursor: pointer;
											background-color: #832555;
											border-radius: 50%;
										}
									}
									.wp-content {
										a {
											padding: 0;
										}
										.ez-excerpt-content {
											white-space: nowrap;
											overflow: hidden;
											text-overflow: ellipsis;
											a {
												float: right;
											}
										}
									}
                                }
                            }
                            .columns {
                                padding: 0;
                            }
                        }

                        #search_output {
                            top: 60px;
                            right: 0;
							list-style-type: none;
                            &::before {
                                left: auto;
                                right: 40px;
                            }

                                li {
                                    padding:0;
                                    h2 {padding:0.4rem;}
                                    a {
                                        display:block;
                                        margin:0;
                                        padding:0 0.4rem 0.4rem 0.4rem;
                                    }
                                    .search_item_spacer {
                                        margin: 0;
                                    }

                                    .search_picture {
                                        max-width: 80px;
                                    }
                                }
                            .search_headline {border-bottom:none;}
                            li.search_item {
                                font-size:0.8rem;
                                img.search_picture {max-height:80px;}
                                h3 {margin-bottom:0.3rem;color:$primary-color;}
                                p.artnr {margin:0;font-size:0.7rem;color:black;}
                                .callout {
                                    margin-bottom:0.4rem;
                                    padding:0.4rem;
                                    a {
                                        padding:0;
                                        div {
                                            margin:0;
                                            &:first-child {
                                                display:flex;
                                                img.search_picture {align-self:center;}
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    label { font-size: 11px; }
                    .header_normal {
                        height: 100%;
                        padding: 0;
                        @include display-flex;
                        @include flex-align(center);
                        @include flex-justify(space-between);
                        margin-left: auto;
                        margin-right: auto;

                        &.header_items {
                            @include display-flex;
                            @include flex-justify(start);

                            .cart,.catalog{
                                padding: 0 15px;
                            }

                            .header_item {
                                @include display-flex;
                                @include flex-all-centered;
                                margin-right: 0px;
                                background-color: transparent;
                                color: white;

                                #header_logo{
                                    width: 80px;
                                    height: auto;
                                }

                                &.small_nav_open {
                                    .hamburger{
                                        padding: 0;
                                        .hamburger-box{
                                            height: auto;
                                            width: 30px;
                                            .hamburger-inner{
                                                background-color:#fff;
                                                width: 30px;
                                                height: 1px;
                                                &::before{
                                                    background-color:#fff;
                                                    width: 30px;
                                                    height: 1px;
                                                    top: -8px;
                                                }
                                                &::after{
                                                    background-color:#fff;
                                                    width: 30px;
                                                    height: 1px;
                                                    bottom: -8px;
                                                }
                                            }
                                        }
                                        &.is-active{
                                            .hamburger-box{
                                                .hamburger-inner{
                                                    background-color:#f29fc5;
                                                    &::before{
                                                        background-color:#f29fc5;
                                                    }
                                                    &::after{
                                                        background-color:#f29fc5;
                                                        bottom: 0px;
                                                    }
                                                }
                                            }
                                        }
                                        &:hover{
                                            .hamburger-box{
                                                .hamburger-inner{
                                                    background-color:#f29fc5;
                                                    &::before{
                                                        background-color:#f29fc5;
                                                    }
                                                    &::after{
                                                        background-color:#f29fc5;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                .header_icon {
                                    line-height: 35px;
                                    font-size: 30px;
                                    font-weight: 500;
                                }

                                &.tel{
                                    padding: 0 15px;
                                }

                                &.login {
                                    position: relative;
                                }

                                &:hover {
                                    cursor: pointer;
                                    color: #f29fc5;
                                    transition: color 0.3s ease;
                                }
                            }
                        }

                        @media (min-width: 1024px){
                            .tel,.mail,.account,.cart,.catalog{
                                & > span, .header_item > span{
                                    padding-left: 5px;
                                }
                                .header_icon{
                                    padding-left: 0;
                                }
                            }
                        }

                        .tel{
                            margin-top:3px;
                            @media (min-width: 1024px){
                                padding-left: 0!important;
                            }
                        }

                        .mail{
                            margin-top:5px;
                        }

                        .changesearch{
                            margin-top:5px;
                        }

                        .account{
                            margin-top:5px;
                            .account-details {
                                position: absolute;
                                display: none;
                                z-index: 10;
                                background-color: white;
                                padding: 0;
                                top: calc(100% + 10px);
                                width: 220px;
                                box-shadow: 0px 0px 13px 1px rgba(0, 0, 0, 0.56);

                                &::before {
                                    position: absolute;
                                    content: "";
                                    width: 0px;
                                    height: 0px;
                                    bottom: 100%;
                                    left: 20px;
                                    border-style: solid;
                                    border-width: 0 10px 10px 10px;
                                    border-color: transparent transparent white transparent;
                                }

                                > div {
                                    padding: 0 1.2rem;
                                    border-bottom: 1px solid grey;
                                    min-height: 2.8rem;
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    &.dropdown_headline {
                                        display: table;
                                        width: 100%;
                                        h3 {margin-top: 1rem;margin-bottom: 0.2rem;color:$primary-color;}
                                    }
                                    #login_form {
                                        padding: 0.6rem 0
                                    }
                                    a {
                                        display:flex;
                                        margin:0;
                                        padding:0;
                                        width: 100%;
                                        justify-content: space-between;
                                    }
                                    span {color:black;align-self:center;}
                                    span.icon {
                                        display:inline-block;
                                        width:2.2rem;
                                        height:2.2rem;
                                        border-radius:50%;
                                        font-family: "riegel-icons-web-regular";
                                        font-size: 1.6rem;
                                        text-align: center;
                                        color:$primary-color;
                                        background-color:#F5D1E1;
                                    }
                                }
                            }

                            #account-details {
                                display: none;
                            }

                            #account-details:checked + .account-details {
                                display: block;
                            }

                        }

                        .cart{
                            margin-top:5px;
                            @media (min-width: 1024px){
                                padding-right: 0!important;
                            }
                        }

                        .catalog {
                            margin-top:5px;
                            @media (min-width: 1024px){
                                padding-left: 0!important;
                            }
                        }
                    }
                }
            }


			//********************************************//
			//***************** DROPDOWN *****************//
			//********************************************//

			.warenkorb_preview {
				position: relative;
				#mein_warenkorb {
					display: none;
					position: absolute;
					padding: 1rem;
					right: 0px;
					width: 300px;
					max-height:700px;
					font-weight: 400;
					border: none;
					border-radius: 0;
					top: calc(100% + 10px);
					-webkit-box-shadow: 0px 0px 13px 1px rgba(0, 0, 0, 0.56);
					-moz-box-shadow: 0px 0px 13px 1px rgba(0, 0, 0, 0.56);
					box-shadow: 0px 0px 13px 1px rgba(0, 0, 0, 0.56);
					background-color: white;
					color: black;
					font-weight: 100;
					z-index: 1000;
					&::before {
						position: absolute;
						content: "";
						width: 0px;
						height: 0px;
						bottom: 100%;
						right: 20px;
						border-style: solid;
						border-width: 0 10px 10px 10px;
						border-color: transparent transparent white transparent;
					}

					a {
						color: black;
					}

					.dropdown_headline,
					.dropdown_headline a {
						color: #797979;
						font-size: 16px;
					}

					.dropdown_value,
					.dropdown_value a {
						color: black;
						font-size: 14px;
					}
					.button {
						background-color: #892251;
					}
					.cart_arts_container {
						max-height: 400px;
						overflow-x: hidden;
						overflow-y: scroll;
					}
				}
			}
		}

		#header_2_container {
			position: absolute;
			left: 0;
			top: $first-header-height;
			width: 100%;
			height: $second-header-height;
			background: #fff;
			z-index: 2;
			transition: height 0.5s;

			body.with-breadcrumbs & {
				height: $second-header-height+26px;
				transition: height 0.5s;

				.large-6 {
					padding-bottom: 0;
					transition: padding-bottom 0.505s ease-out;
				}
			}

			#header_2 {
				//background-color: #fff;
				height: $second-header-height;
				align-items: center;
				transition: height 0.5s;
				flex-wrap: nowrap;

				.large-3 {
					flex: 0 0 ($global-width - 768px)/2;
					max-width: ($global-width - 768px)/2;
				}

				.large-6 {
					flex: 0 0 768px;
					max-width: 768px;
					padding-right: 1rem;
					padding-left: 1rem;
				}

				.header_2_items {
					display: flex;
					align-items: center;
					justify-content: space-evenly;
				}

				#header_logo {
					width: 97px;
					transition: width 0.5s;
				}

				#show_search_checkbox {
					display: none;
				}

				#show_search_checkbox:checked + .header_normal {
					display: none;
				}

				#show_search_checkbox:checked + .header_normal + #header_search {
					display: block;
				}

				#header_search {
					position: relative;
					@include display-flex;
					@include flex-align(start);
					padding: 0;
					height: 100%;

					#search_container {
						margin-left: -0.9375rem;
						padding-right: 0;
						form {@include display-flex;}
					}

					#search_input {
						width: calc(100% - 36px);
						margin: 0 1px 0 0;
						height: 35px;
						border-radius: 0;
						border: 1px solid #C8C8C8;
						box-shadow: none;
						font-size: 15px;
					}

					.button {
						height: 35px;
						width: 35px;
						margin: 0;
					}

					#search_output {
						top: 80%;
						right: 0;

						&::before {
							left: auto;
							right: 40px;
						}
						li {
							padding:0;
							h2 {padding:0.4rem;}
							a {
								display:block;
								margin:0;
								padding:0 0.4rem 0.4rem 0.4rem;
							}
							.search_item_spacer {
								margin: 0;
							}

							.search_picture {
								max-width: 80px;
							}
						}
					}

					.header_icon.search {
						padding: 0;
						font-family: "riegel-icons-web-regular";
						font-size: 30px;
						line-height: 35px;
					}
				}

				.navbar {
					margin: 0 -15px;
					padding: 0;
					height: 100%;
					list-style: none;
					transition: margin 0.5s;

					ul.level_1 {
						display: flex;
						//justify-content: space-around;
					}

					li {
						position: relative;
						float: left;

						&:after {
							content: "";
							width: 1px;
							height: 16px;
							background-color: #fff;
							position: absolute;
							right: -2px;
							top: 9px;
							transform: skew(-45deg);
						}

						&:last-child {
							&:after {
								display: none;
							}
						}

						&.active {
							a {
								strong {
									color: $primary-color;
									font-weight: normal;
								}
							}
						}

						&.trail{
							a {
								span {
									color: $primary-color;
									font-weight: normal;
								}
							}
							ul{
								li{
									a{
										span.active{
											color: $primary-color;
										}
									}

									a{
										span{
											color: #474747;
										}
									}
								}
							}
						}

						a {
							display: inline-block;
							margin: 0;
							padding: 0 15px;
							font-family: "CaeciliaLTPro", Times, sans-serif;
							font-size: 15px;
							text-decoration: none;
							color: #474747;
							overflow: hidden;
							position: relative;
							transition: color 0.3s ease;
							&:hover {
								color: $primary-color;
								transition: color 0.3s ease;
							}
						}

					}
				}
			}

			#header_2_breadcrumbs {
				position: absolute;
				left: 50%;
				bottom: 0;
				width: 100%;
				transform: translateX(-50%);
				height: 26px;
				opacity: 1;
				transition: opacity 0.25s ease 0.25s;

				.large-offset-3 {
					margin-left: ($global-width - 768px)/2;
				}

				.large-6 {
					flex: 0 0 768px;
					max-width: 768px;
					padding-right: 1rem;
					padding-left: 1rem;
				}

				ul {
					margin: 0 -3px;
					padding: 0 3px;
					border-top: 1px dotted #C8C8CA;

					li {
						float: left;
						margin: 0;
						padding: 0;
						list-style: none;
						line-height: 24px;
						font-size: 9px;

						&::before {
							content: "›";
							display: inline-block;
							padding: 0 7px;
						}

						&.first::before {
							content: "";
							padding: 0;
						}
					}
				}

				div.with-spans {
					margin: 0 -3px;
					padding: 0 3px;
					border-top: 1px dotted #C8C8CA;

					span.breadcrumb_spacer {
						display: none;
					}

					span.breadcrumb_item {
						float: left;
						display: block;
						margin: 0;
						padding: 0;
						list-style: none;
						line-height: 24px;
						font-size: 9px;

						&::before {
							content: "›";
							display: inline-block;
							padding: 0 7px;
						}

						&.first::before {
							content: "";
							padding: 0;
						}
					}
				}
			}

			&.open #header_2_breadcrumbs {
				opacity: 0;
				transition: opacity 0.25s;
			}

			.navbar-links {
				height: 100%;

				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
	}

	body.reduced-header & {
		padding-top: $second-header-reduced-height;
		transition: padding-top 0.5s;

		#header {
			#header_wrap {
				#header_1_container {
					margin-bottom: $second-header-reduced-height;
					transition: margin-bottom 0.5s;
				}

				#header_2_container {
					height: $second-header-reduced-height;
					transition: height 0.5s;

					#header_2 {
						height: $second-header-reduced-height;
						transition: height 0.5s;

						#header_logo {
							width: 70px;
							transition: width 0.5s;
						}
					}
				}
			}
		}
	}

	body.with-breadcrumbs.reduced-header & {
		padding-top: $second-header-reduced-height+26px;

		#header {
			#header_wrap {
				#header_2_container {
					.large-6 {
						padding-bottom: 26px;
						transition: padding-bottom 0.5s;
					}
				}
			}
		}
	}
}

.to_top{
	padding: 7px 0!important;
}

#header .navbar {
	.invisible {
		display: block;
		height: 0;
	}

	ul {
		margin: 0;
		padding: 0;

		li {
			list-style: none;
			margin: 0;
			padding: 0;
		}
	}

	ul.level_1 {
		margin: 9px 0 0;

		& > li {
			position: relative;
			display: block;
			float: left;

			a {
				position: relative;
				z-index: 1;
			}
		}

		ul.level_2 {
			position: absolute;
			visibility: hidden;
			top: 0;
			left: 0;
			width: 300px;
			padding: 60px 0 30px;
			transition: visibility 0s linear 0.1s, opacity 0.1s;
			opacity: 0;

			ul {
				padding-left: 20px;
			}

			li {
				float: none !important;
			}
		}
		& > li:hover {
			ul.level_2 {
				visibility: visible;
				opacity: 1;
				transition: opacity 0.1s ease-in 0.15s;
			}
		}
	}
}

.og_nav {
	position: relative;
	background-color: rgba(198, 84, 140, 0.5);
	padding: 10px;
	width: 49%;
	height: 11rem;
	float: left;
	// border: 1px solid #b3b3b3;
	border-radius: $global-radius;
	margin-bottom: 2%;
	@include play-animation(listing-scale, 1s, false);

	img {
		position: absolute;
		top: 16px;
		right: 10px;
		z-index: 1;
		max-height: 85%;
	}

	h3 {
		font-weight: bold;
		color: #892251;
	}

	color: $primary-color;

	a {
		color: white;
	}

	:hover {
		color: lighten(#892251, 50);
	}

	a:hover {
		color: lighten(#892251, 50);
	}
}

.og_nav:nth-child(odd) {
	margin-right: 2%;
}

@media screen and (max-width: 800px) {
	.og_nav {
		width: 100%;
	}
}

//**********************************************************************//
//*************************** HEADER MODULES ***************************//
//**********************************************************************//
//********** Search **********//
#tabs {
	.filter_icon {
		width: 25%;
		height: 100%;
	}

	#range_slider_price .ui-slider-handle .tip {
		background-color: white;
		color: $primary-color;
		border: 1px solid $primary-color;
	}

	#range_slider_price .ui-slider-range {
		background: $primary-color;
	}

	#filter_brand_input {
		border: 1px solid lightgray;
		background-color: white;
	}

	#filter_brand_content {
		input[type="checkbox"] {
			margin: 0px;
		}
	}

	.ui-tabs-nav {
		border: 1px solid #bcbbbb;

		.ui-tabs-nav .ui-tabs-tab.ui-state-default {
			background-color: white;
		}
	}

	.tab_public_element {
		border: 1px solid #bcbbbb;

		.headline {
			margin: 0;

			span {
				color: $primary-color;
				font-size: 25px;

				.icon_plus,
				.icon_minus {
					top: 3px;
				}
			}
		}

		.content {
			border-top: 1px solid lightgray;
		}
	}

	.ui-tabs-panel {
		border: 1px solid #bcbbbb;
	}

	.button {
		background-color: $primary-color;
		&.secondary {
			background-color: $secondary-color;
		}
	}

	.ui-state-active {
		background-color: $primary-color !important;
	}

	.svg_icon {
		fill: $primary-color;
	}

	.doyoumean_headline {
		color: #dd4b39;
	}

	.doyoumean_value {
		color: #1a0dab;
	}

	.information_item {
		.headline {
			a {
				color: #1a0dab;
			}

			a:visited {
				color: #609;
			}
		}

		.url {
			color: green;
		}

		.description {
			color: gray;
		}

		.hit {
			color: $primary-color;
		}

		.keywords .keyword_container .keyword {
			color: #1a0dab;
		}

		.keywords .keyword_container .keyword:visited {
			color: #609;
		}
	}
}

//********** /Search **********//

//********** Autocomplete **********//
[data-ez-search-input] {
	background-color: white;
	margin-top: 0px;
	border-radius: 0px;
	height: 100%;
	background-color: #5aadb5;
	max-width: 100%;
	margin-bottom: 0px;
}

[data-ez-search-input]:hover {
	background-color: $secondary-color;
}

[data-ez-search-output] {
	background-color: white;

	-webkit-box-shadow: 0px 0px 13px 1px rgba(0, 0, 0, 0.56);
	-moz-box-shadow: 0px 0px 13px 1px rgba(0, 0, 0, 0.56);
	box-shadow: 0px 0px 13px 1px rgba(0, 0, 0, 0.56);

	right: 0px;
	top: 40px;

	// max-width: 500px;

	margin-right: 10px;

	ul {
		li.search_headline {
			border-bottom: 1px solid lightgray;
		}

		li[data-search-item-active="true"] {
			background-color: lightgray;
		}
	}

}

[data-ez-search-output]::before {
	display: none;
}

@media screen and (max-width: 40em) {
	[data-ez-search-output] {
		margin-right: 0px;
		margin-top: 164px;
		max-width: initial;
	}
}

//********** /Autocomplete **********//
//********** Warenkorb Thumb **********//
.warenkorb_thumb {
	position: absolute;
	background-color: red;
	width: 20px;
	text-align: center;
	border-radius: 50%;
	top: 0;
	right: 0;
    padding: 0;
}
//********** /Warenkorb Thumb **********//
//********** ToTop-Button **********//
[data-ez-module-totop] {
	background-color: #892251;
	display: inline;
	transition: opacity 0.7s;
	opacity: 0;

	&:hover,
	&:focus{
		color: white;
	}
	&.ezShowToTop {
		opacity: 1;
	}
}
//********** /ToTop-Button **********//

//**************************************************************//
//*************************** FOOTER ***************************//
//**************************************************************//
#footer_wrap {
	font-size: 16px;
	font-weight: 400;

	@include set-normal-and-link-color(white);

	ul {
		list-style: none;
		margin-left: 0px;

		li {
			padding: 2.5px 0px;
		}
	}

	a:hover {
		color: $primary-color;
	}

	#quality_container {
		background-color: #F6F6F6;

		#quality {
			color: #706F6F;
			padding: 30px 0;
			font-size: 12px;

			img {
				margin-top: 15px;
				padding: 0 15px;
				width: 650px;
				@media screen and (min-width: 768px) {
					margin-left: 40px;
					margin-top: 0px;
				}
			}
		}
	}

	#customer_specials_container {
		background-color: #C6548C;
		color: #fff;
		padding: 33px 0;

		.row {
			max-width:1050px;

			.columns {
				position: relative;
				padding: 5px 30px 0 70px;

				&:last-child {
					padding: 5px 30px 0 70px;
					border-right: none;
				}
                @media (min-width: 640px) {
                    padding: 5px 80px 0 80px;
                    border-right: 1px solid #fff;
                    &:last-child {
                        padding: 5px 80px 0 80px;
                    }
                }
                @media (min-width: 768px) {
                    padding: 5px 105px 0 105px;
                    &:last-child {
                        padding: 5px 105px 0 105px;
                    }
                }
			}
		}

		p {
			color: inherit;
			font-size: 12px;
		}

		.newsletter-icon,
		.probeflasche-icon {
			position: absolute;
			left: 30px;
			top: 0;
			font-size: 3rem;
			line-height: 1;
            @media (min-width: 768px) {
                left: 60px;
            }
		}

		.head_text {
			font-family: $font_family_headline;
			font-size: 25px;
			font-weight: 500;
			line-height: 30px;
			color:#fff;
		}

		#newsletter {
            @media (max-width: 639px) {
                margin-bottom:30px;
            }
            @media (max-width: 767px) {
                margin-bottom:30px;
            }
			.formbody {
				position: relative;
				height: 38px;
				width: 182px;

				label {
					display: none;
				}

				input {
					font-family: $font_family_headline;
					height: 38px;
					width: 150px;
					border: none;

				}

				::placeholder {
					color: #A9A9A9;
					opacity: 1;
				}

				:-ms-input-placeholder {
					color: #A9A9A9;
				}

				::-ms-input-placeholder {
					color: #A9A9A9;
				}

                [type="email"]{
                    padding: 4px 13px 0px 10px;
                }

				button {
					position: absolute;
					right: 0;
					bottom: 0;
                    margin: 0;
                    padding: 0;
                    font-family: unset;
                    color:#474747;
					width: 38px;
					height: 0;
					padding-top: 38px;
					overflow: hidden;
					background-color: #F4F4F4;
					border: none;

					&::before {
						content: "X";
						position: absolute;
						display:block;
						top: 0;
						left: 8px;
						font-family: $font_family_riegel_icons;
						font-size: 45px;
					}
				}
			}
		}
	}

	#downloads_container {
		padding: 35px 0 30px;
		font-size: 12px;
		color: #707070;
		text-align: center;
        @media (max-width: 575px) {
            span{
                display: block;
                width: 100%;
                margin-bottom: 15px;
            }
        }
		a {
            display: inline-block;
			color: #707070;
            text-align: center;
            padding-left: 15px;
            padding-right: 15px;

			&:hover {
				color: $primary-color;
			}

            @media (min-width: 640px) {
                padding-left: 4rem;
                padding-right: 0px;
            }
		}

		.fs-riegel-icons-web-regular {
			display: inline-block;
			padding: 0 0.5rem 0 0;
			font-size: 3rem;
			line-height: 1;
			vertical-align: middle;
		}
	}

	#payment_methods_container {
		background-color: #fff;

		#payment_methods {
			color: #706F6F;
			padding: 30px;
		}
	}

	#footer_1_container {
		background-color: #892251;

		#footer_1 {
			margin-left: auto;
			margin-right: auto;
			max-width: $global-width;
			font-size: 12px;
			padding: 33px 0;

            h3 {
                margin-top: 1rem;
            }

            a:hover {
                color: #F29FC5;
            }

			.header_titles {
				font-size: 16px;
				font-weight: 600;
				font-family: $font_family_headline;
				color: #fff;
			}

            .footer_facebook {
                position: absolute;
                display: block;
                background: url("../images/icons/socialmedia_facebook.svg") no-repeat;
                background-size: 100%;
                width: 50px;
                height: 50px;
                top: 0;
                left: 210px;
                outline: none;
            }

            .footer_youtube {
                position: absolute;
                display: block;
                background: url("../images/icons/socialmedia_youtube.svg") no-repeat;
                background-size: 100%;
                width: 50px;
                height: 50px;
                top: 0;
                left: 250px;
                outline: none;
            }

            .footer_instagram {
                position: absolute;
                display: block;
                background: url("../images/icons/socialmedia_instagram.svg") no-repeat;
                background-size: 100%;
                width: 50px;
                height: 50px;
                top: 0;
                left: 290px;
                outline: none;
            }

            .footer_linkedin {
                position: absolute;
                display: block;
                background: url("../images/icons/socialmedia_linkedin.svg") no-repeat;
                background-size: 100%;
                width: 50px;
                height: 50px;
                top: 0;
                left: 330px;
                outline: none;
            }

            @media (max-width: 300px) {
                .footer_facebook,
                .footer_youtube,
                .footer_instagram,
                .footer_linkedin{
                    position: static;
                    display: inline-block;
                }
            }

			#footer_left {
                position: relative;

				.footer_left_description {
					color: #F29FC5;
				}

                .footer_left_contact {
					font-size: 20px;
					font-weight: 600;
					font-family: $font_family_headline;
					line-height: 1.1;
				}
			}

			#footer_center {
				.footer-border-left {
					border-left-width: 1px;
					border-left-style: solid;
					border-left-color: #C6548C;
				}

				ul {
					li {
						a {
							color: #F29FC5;
						}
					}
				}

				.row {
					height: 100%;
				}
			}

			#footer_right {
				// @include display-flex;
				// @include flex-wrap(wrap);
				// @include flex-justify(center);

				.zahlungsarten {
					position: relative;
					padding: 12px;
					display: flex;
					justify-content: center;

					&:before,
					&:after {
						content: "";
						position: absolute;
						background-color: #9f9e9d;
						height: 1px;
						width: 60%;
						left: 0;
						right: 0;
						margin: 0 auto;
						opacity: .4;
					}

					&:before {
						top: 0;
					}

					&:after {
						bottom: 0;
					}

					.zahlungsarten_img {
						max-height: 36px;
					}
				}

				.zertifikate {
					padding: 12px;
					@include display-flex;
					justify-content: space-evenly;
				}

				#social_media {
					@include display-flex;
					@include flex-justify(center);

					width: 100%;
					margin: 0px;
					font-size: 30px;

					li {
						margin: 0px 10px;
					}
				}
			}

			#footer_mwst {
				color: gray;
				font-size: 12px;
			}

			.footer_icon {
				color: #b1b0b0;

				&:hover {
					color: $primary-color;
				}
			}
		}
	}
}

@media screen and (max-width: 39.9375rem) {
	#footer_wrap {
		#footer_1_container {
			#footer_1 {
				padding-bottom: 20px;

				#footer_right,
				#footer_right #social_media {
					@include flex-justify(flex-center);
				}

				#footer_center {
					margin: 20px 0px;
				}
			}
		}
	}
}

//**********************************************************************//
//*************************** FOOTER MODULES ***************************//
//**********************************************************************//
//********** Newsletter Form **********//
#newsletter_form {
	.newsletter_elements {
		.newsletter_email {
			.email {
				opacity: .8;
				padding: 4px 10px;
				border: 0 none;
				width: 85%;
				margin: 0;
			}
		}

		.newsletter_subscribe {
			position: relative;
			overflow: hidden;
			margin-bottom: 10px;

			.subscribe {
				background-color: transparent;
				width: 100%;
				border: 0 none;
				padding: 8px 8px;
				color: #fff;
				position: relative;
				text-align: left;
				cursor: pointer;
				font-size: 16px;
				font-family: $font_family_headline;
			}

			&:before {
				content: "";
				position: absolute;
				width: 100%;
				left: -90px;
				top: 0;
				background-color: $primary-color;
				width: 200px;
				height: 100%;
				z-index: 0;
				-webkit-transform: skew(-45deg);
				transform: skew(-45deg);
				-webkit-transition: all .25s cubic-bezier(.075, .82, .165, 1);
				transition: all .25s cubic-bezier(.075, .82, .165, 1);
			}

			&:hover:before {
				left: -73px;
			}
		}
	}
}

//********** /Newsletter_Form **********//

//********** /UserCentrics **********//
.usercentrics-button .uc-main-banner .btn-deny {
    min-width: 100% !important;
    border: 1px solid rgb(137, 34, 81) !important;
    text-transform: none !important;
    color: rgb(137, 34, 81) !important;
    background-color: transparent !important;

    @media (min-width: 768px) {
        min-width: unset !important;
        max-width: 25%;
    }
}

.usercentrics-button.layout-1 .uc-banner-content .btn-list .uc-btn.uc-btn-primary {
    min-width: 100% !important;
    margin: 15px 0 !important;
    border: 1px solid rgb(137, 34, 81) !important;
    text-transform: none !important;
    color: rgb(137, 34, 81) !important;
    background-color: transparent !important;

    @media (min-width: 768px) {
        min-width: unset !important;
        max-width: 25%;
        margin: 0 15px !important;
    }
}

.usercentrics-button .uc-main-banner .more-info-button {
    min-width: 100% !important;
    border: 1px solid rgb(137, 34, 81) !important;
    text-transform: none !important;
    color: rgb(137, 34, 81) !important;
    background-color: transparent !important;

    @media (min-width: 768px) {
        min-width: unset !important;
        max-width: 25%;
    }
}

#usercentrics-button .uc-banner-links a,
#usercentrics-button .uc-banner-links-separator {
    color: rgb(137, 34, 81) !important;
}

#uc-banner-text,
.uc-btn-footer-container {
    max-width: 1054px !important;
    margin: 0 auto !important;
}

.uc-banner-links {
    display: none !important;
}

.uc-btn-footer-container {
    text-align: center;
}

.btn-list {
    @media (min-width: 768px) {
        left: -50%;
        transform: translate(61%, 0);
    }
}

#uc-banner-text {
    text-align: center;
}

#test1 {
	[for="weinanalyse"], [for="nutritions"] {
		margin: 0;
		padding: 12px 8px;
		border-bottom: 0.5px solid #222;
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
		color: #86214f;
	}
	// trixing a little
	[for="nutritions"] {
		margin-left: -5px;
	}
	#weinanalyse-content,#nutritions-content {
		padding: 11px 0px;
	}
	#weinanalyse:checked {
		~#nutritions-content {
			display: none;
		}
		~[for="weinanalyse"] {
			border: 0.5px solid #222;
			border-bottom: none;
			color: #4d4d4d;
		}
	}
	#nutritions:checked {
		~#weinanalyse-content {
			display: none;
		}
		~#nutritions-content {
			p {
				padding: 0 8px;
			}
			table {
				margin: -1px;
				td {
					&:nth-child(1) { padding-right: 0; }
					&:nth-child(2) { padding-left: 0; }
				}
			}
		}
		~[for="nutritions"] {
			border: 0.5px solid #222;
			border-bottom: none;
			color: #4d4d4d;
		}
	}
}