@mixin pagination ( $color_selected ){
	.pagination_pages {
		@include display-flex;
		@include flex-all-centered;

		list-style: none;

		.pagination_item{
			@include display-flex;
			@include flex-all-centered;

			width: 35px;
			height: 35px;

			margin: 0px 10px;

			background-color: transparent;
			color: black;

			border-radius: 100%;

			&.active{
				background-color: $color_selected;
				color: white;
			}

			&.prev,
			&.next{
				font-size: 30px;
				font-weight: bold;
			}

			&.prev{ margin-left: 0px; margin-right: 20px; }
			&.next{ margin-right: 0px; margin-left: 20px; }

			&.prev.disabled,
			&.next.disabled{
				color: gray;
				pointer-events: none;
			}

			&:not(.not_selectable):hover{
				cursor: pointer;
			}
		}
	}
}

@media screen and (max-width: 39.9375rem) {
	.pagination_pages{
		.pagination_item{
			margin: 0px 0px !important;
		}
	}
}