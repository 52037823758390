.tooltip_container{
	position: relative;

	.tooltip {
		position: absolute;
		display: none;

		min-width: 200px;

		border: none;
		border-radius: 4px;

		top: 100%;

		padding: 8px 10px;

		background-color: black;
	}

	&.right .tooltip{ right: 0%; }
	&.left .tooltip{ left: 0%; }
	&.bottom .tooltip{ top: calc( 100% + 10px ); }
	&.top .tooltip{ top: calc( -100% - 10px ); }

	&:hover .tooltip{
		display: block;
	}
}