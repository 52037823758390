@mixin display-flex() {
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}
@mixin flex-direction($dir) {
	$dir: row !default;

	-webkit-flex-direction: $dir;
		-ms-flex-direction: $dir; // (???)
	flex-direction: $dir;
}
@mixin flex-direction ($direction){
	-webkit-flex-direction: $direction;
	flex-direction: $direction; 
}
@mixin flex-inline (){
	display: -ms-inline-flexbox;
	display: -webkit-inline-flex;
	display: inline-flex;
}
@mixin flex-wrap($wrap) {
	$wrap: wrap !default;

	-webkit-flex-wrap: $wrap;
	-ms-flex-wrap: $wrap;
	flex-wrap: $wrap;
}
@mixin flex-justify($just) {
	$just: space-between !default;

	-webkit-justify-content: $just;
	// "flex-start" => "start"
	@if $just == flex-start {
		-ms-flex-pack: start;
	// "flex-end" => "end"
	} @else if $just == flex-end {
		-ms-flex-pack: end;
	// "center" => "center"
	} @else if $just == center {
		-ms-flex-pack: center;
	// "space-between" => "justify"
	} @else if $just == space-between {
		-ms-flex-pack: justify;
	// "space-around" => "distribute"
	} @else if $just == space-around {
		-ms-flex-pack: distribute;
	}
	justify-content: $just;
}
@mixin flex-align($align) {
	$align: flex-start !default;

	-webkit-align-items: $align;
	// "flex-start" => "start"
	@if $align == flex-start {
		-ms-flex-align: start;
	// "flex-end" => "end";
	} @else if $align == flex-end {
		-ms-flex-align: end;
	// "center" => "center"
	} @else if $align == center {
		-ms-flex-align: center;
	// "stretch" => "stretch"
	} @else if $align == stretch {
		-ms-flex-align: stretch;
	}
	align-items: $align;
}
@mixin flex-grow ( $grow ){
	-webkit-box-flex: $grow;
	-webkit-flex-grow: $grow;
	-moz-flex-grow: $grow;
	-ms-flex-positive: $grow;
	flex-grow: $grow;
}
@mixin flex-basis ( $basis ){
	-webkit-flex-basis: $basis;
	-moz-flex-basis: $basis;
	-ms-flex-preferred-size: $basis;
	flex-basis: $basis;
}
@mixin set-flex($grow, $shrink, $basis) {
	$grow: 0 !default;
	$shrink: 0 !default;
	$basis: auto !default;

	-webkit-flex: $grow $shrink $basis;
	-ms-flex: $grow $shrink $basis;
	flex: $grow $shrink $basis;
}
@mixin flex-align-self($align) {
	$align: flex-start !default;

	-webkit-align-self: $align;
	-ms-align-self: $align;
	align-self: $align;
}

@mixin flex-order ( $order ){
	-webkit-box-ordinal-group: $order;  
  -moz-box-ordinal-group: $order;     
  -ms-flex-order: $order;     
  -webkit-order: $order;  
  order: $order;
}

@mixin flex-all-centered (){
	@include flex-justify( center );
	@include flex-align( center );
}

/* ### start: placeholder selector ### */
@mixin set-placeholder-style($property, $value) {
	&::-webkit-input-placeholder {/* Chrome/Opera/Safari */
		#{$property}: $value;
	}
	&::-moz-placeholder {/* Firefox 19+ */
		#{$property}: $value;
	}
	&:-ms-input-placeholder {/* IE 10+ */
		#{$property}: $value;
	}
	&:-moz-placeholder {/* Firefox 18- */
		#{$property}: $value;
	}
}
@mixin row($test) {
	content: map-get($test, "just");
	//@include justify-center();
	//@include flex-wrap();
}

@mixin transition ( $value... ){
	transition: $value;
	-webkit-transition: $value;
	-moz-transition: $value;
	-o-transition: $value;
}

@mixin transform ( $value... ){
	transform: $value;
	-webkit-transform: $value;
	-moz-transform: $value;
	-ms-transform: $value;
	-o-transform: $value;
}

@mixin box-shadow ( $value... ){
	box-shadow: $value;
	-webkit-box-shadow: $value;
	-moz-box-shadow: $value;
}

@mixin play-animation ( $name, $duration, $inifinity ) {
	-webkit-animation-name: $name;
	-webkit-animation-duration: $duration;
	
	-moz-animation-name: $name;
	-moz-animation-duration: $duration;
	
	-ms-animation-name: $name;
	-ms-animation-duration: $duration;
	
	animation-name: $name;
	animation-duration: $duration;

	-webkit-animation-timing-function: linear;
	-moz-animation-timing-function: linear;
	-ms-animation-timing-function: linear;
	animation-timing-function: linear;

	@if $inifinity {
		-webkit-animation-iteration-count: infinite;
		-moz-animation-iteration-count: infinite;
		-ms-animation-iteration-count: infinite;
		animation-iteration-count: infinite;
	}
}

@mixin panel (){
	position: relative;

	background-color: #eee;

	border: 1px solid lightgray;
	border-radius: $global-radius;

	margin-bottom: 20px;

	padding: 20px;

	.edit{
		position: absolute;

		right: 5px;
		top: 5px;

		padding: 5px;
    background-color: #c5c5c5;
    border: 1px solid #afafaf;
    border-radius: 5px;
    color: $primary-color;
	}
}

@mixin block ( $font-color, $background-color ){
	.block {
		margin: 0px;
		margin-bottom: 10px;

		background-color: $background-color;

		border: 1px solid darken( $background-color, 10 );
		border-radius: $global-radius;

		padding: 10px;

		font-size: 18px;

		color: $font-color;

		h2{
			font-size: 18px;
			margin: 0px;
		}

		font-weight: bold;
		a,
		span,
		label{
			font-weight: bold;
		}

		a,
		h2{
			color: $font-color;
		}

		a:hover{
			color: $font-color;
		}
	}
}


@mixin set-normal-and-link-color ( $color ) {
	color: $color;
	a {
		color: $color;
	}
}

@mixin set-link-hover-color ( $color ) {
	a:hover {
		color: $color;
	}
}