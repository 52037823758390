.cart_thumb_container{
	position: relative;
	.cart_thumb {
		position: absolute;
		top: 2px;
		right: 5px;
		font-size: 15px;
		width: 20px;
		height: 20px;
		border-radius:50%;
		line-height: 19px;
		text-align: center;
		font-weight: bold;
		background-color: #c60f13;
		color: white;
	}
}