@keyframes rotate {
	from {
	    -webkit-transform: rotate(0deg);
	    -o-transform: rotate(0deg);
	    transform: rotate(0deg);
	}
	to {
    	-webkit-transform: rotate(360deg);
    	-o-transform: rotate(360deg);
    	transform: rotate(360deg);
	}
}