.checkout {
	@include display-flex;
	@include flex-justify(space-between);

	font-size: 25px;

	margin: 10px 0px;

	.item{
		position: relative;

		width: 25%;

		.number,
		.name{
			@include display-flex;
			@include flex-align(center);
			@include flex-justify(center);
		}

		.number {
			width: 2.5em;
			height: 2.5em;

			padding: 10px;
			margin: 0 auto;

			border-radius: 50%;

			text-align: center;

			color: white;
			a{
				color: white;
			}

			font-size: 1em;
			font-weight: bold;
		}

		.name{
			height: initial;

			font-size: 18px;
			.icon{
				font-size: 1em;
				color: #a2c63a;
			}

			line-height: 1.5em;
		}

		.description {
			font-size: 16px;
			color: gray;

			line-height: 1em;
		}

		.edit{
			width: fit-content;
			padding: 5px;
			border-radius: 4px;
			background-color: $primary-color;
			color: white;

			margin-left: auto;
			margin-right: auto;

			margin-top: 5px;

			&:hover{
				cursor: pointer;
			}
		}

		.name,
		.description,
		.edit{
			display: block;
			text-align: center;
		}
		
	}

	.item .number .pos{
		position: absolute;
		z-index: 2;
	}

	.item .number:after{
		content: "";

		position: absolute;

		top: calc( ( 2.5em / 2 ) - ( 0.5em / 2 ) );
		height: 0.5em;

		z-index: 1;

		left: 0px;
		right: 0px;
	}

	.item:first-child .number:after{
		left: 50%;
		right: 0px;
	}

	.item:last-child .number:after{
		left: 0px;
		right: 50%;
	}

	.item.pre .number,
	.item.pre .number:after{
		background-color: #a2c63a;
	}
	.item.current .number,
	.item.current .number:after{
		background-color: $secondary-color;
	}
	.item.next .number,
	.item.next .number:after{
		background-color: lightgray;
	}
	
}

@media screen and ( max-width: 800px ){
	.checkout{
		font-size: 20px;

		.item {
			.name{
				font-size: 14px;
			}
			.description {
				font-size: 12px;
			}
		}
	}
}