.flex{
	@include display-flex;
}

.flex-inline {
	@include flex-inline;
}

.flex-wrap{
	@include flex-wrap(wrap);
}

.flex-nowrap{
	@include flex-wrap(nowrap);
}

.flex-between{
	@include display-flex;
	@include flex-justify(space-between);
}

.flex-around{
	@include display-flex;
	@include flex-justify(space-around);
}

.flex-center{
	@include display-flex;
	@include flex-justify(center);
}
.flex-left{
	@include display-flex;
	@include flex-justify(flex-start);
}
.flex-right{
	@include display-flex;
	@include flex-justify(flex-end);
}

.flex-stretch {
	@include flex-align( stretch );
}

.flex-grow{
	@include flex-grow(1);
}

.flex-vertical-center{
	height: 100%;

	@include display-flex;
	@include flex-align(center);
}

.flex-vertical-top{
	@include display-flex;
	@include flex-align(flex-start);
}

.flex-vertical-bottom{
	@include display-flex;
	@include flex-align(flex-end);
}

.flex-vertical-center-inline{
	@include flex-inline;
	@include flex-align(center);
}

.flex-direction-column{
	@include flex-direction(column);
}

.flex-direction-row{
	@include flex-direction(row);
}

.flex-small-center{ @include flex-justify(center); }
.flex-small-left{ @include flex-justify(flex-start); }
.flex-small-right{ @include flex-justify(flex-end); }

.text-small-center{ text-align: center; }
.text-small-left{ text-align: left; }
.text-small-right{ text-align: right; }

@media screen and (min-width: 40em){
	.flex-medium-center{ @include flex-justify(center); }
	.flex-medium-left{ @include flex-justify(flex-start); }
	.flex-medium-right{ @include flex-justify(flex-end); }

	.text-medium-center{ text-align: center; }
	.text-medium-left{ text-align: left; }
	.text-medium-right{ text-align: right; }
}

@media screen and (min-width: 64em) {
	.flex-large-center{ @include flex-justify(center); }
	.flex-large-left{ @include flex-justify(flex-start); }
	.flex-large-right{ @include flex-justify(flex-end); }

	.text-large-center{ text-align: center; }
	.text-large-left{ text-align: left; }
	.text-large-right{ text-align: right; }
}

.panel{
	@include panel;
}

.panel.max-height{
	height: 100%;
}

.panel.white{
	background-color: white;
}

.panel.warning{
	background-color: #fff3cd;
	border-color: #ffeeba;
}

.panel.alert,
.panel.danger{
	background-color: #f8d7da;
	border-color: #f5c6cb;
}

.panel.success{
	background-color: #d4edda;
	border-color: #c3e6cb;
}

.panel.info{
	background-color: #d1ecf1;
	border-color: #bee5eb;
}

.expands {
	width: 100%;
}

.expands-vertical {
	height: 100%;
}

.no-margin{ margin: 0px; }
.no-margin-top{ margin-top: 0px; }
.no-margin-right{ margin-right: 0px; }
.no-margin-bottom{ margin-bottom: 0px; }
.no-margin-left{ margin-left: 0px; }

.no-padding{ padding: 0px; }
.no-padding-top{ padding-top: 0px; }
.no-padding-right{ padding-right: 0px; }
.no-padding-bottom{ padding-bottom: 0px; }
.no-padding-left{ padding-left: 0px; }

@for $i from 1 through 20{
	.margin-#{$i * 5}{ margin: #{$i * 5}px; }
	.margin-#{$i * 5}-top{ margin-top: #{$i * 5}px; }
	.margin-#{$i * 5}-right{ margin-right: #{$i * 5}px; }
	.margin-#{$i * 5}-bottom{ margin-bottom: #{$i * 5}px; }
	.margin-#{$i * 5}-left{ margin-left: #{$i * 5}px; }

	.padding-#{$i * 5}{ padding: #{$i * 5}px; }
	.padding-#{$i * 5}-top{ padding-top: #{$i * 5}px; }
	.padding-#{$i * 5}-right{ padding-right: #{$i * 5}px; }
	.padding-#{$i * 5}-bottom{ padding-bottom: #{$i * 5}px; }
	.padding-#{$i * 5}-left{ padding-left: #{$i * 5}px; }
}

.dropdown_checkbox:not(.show){
	display: none;
}

.dropdown_checkbox:not(:checked) + .dropdown_content{
	display: none;
}

.centered{
	margin: auto;
}

.width-auto { width: auto; }
.height-auto { height: auto; }