$stars: (
	1: (
		1, 10, 11, 12
	),
	1_5: (
		13, 14, 15, 16, 17
	),
	2: (
		18, 19, 2, 20, 21, 22
	),
	2_5: (
		23, 24, 25, 26, 27
	),
	3: (
		28, 29, 3, 30, 31, 32
	),
	3_5: (
		33, 34, 35, 36, 37
	),
	4: (
		38, 39, 4, 40, 41, 42
	),
	4_5: (
		43, 44, 45, 46, 47
	),
	5: (
		48, 49, 5, 50 
	)
);

@keyframes show-bar{
	from { transform: translateX(-100%); }
	to { transform: translateX(0%); }
}

@mixin ratings($url, $points, $bar_color) {
	.ratings {
		list-style-type: none;
		.rating_item {
			margin-bottom: 15px;

			.rating_item_from {
				font-size: 13px;
				font-weight: bold;

				.rating-verified-icon{
					color: #008bff;
				}
			}
			.rating_item_text,
			.rating_item_answer {
				font-size: 15px;
			}
			.rating_item_answer {
				font-style: italic;
				color: #585151;
			}
		}
	}
	
	.rating_overview {
		color: $primary-color;
		margin-top: 10px;
	}

	.rating_overview_bar{
		list-style: none;

		.rating_level{
			@include display-flex;
			@include flex-align(center);

			.rating_level_label{
				width: 90px;
			}

			.rating_level_bar{
				border: 1px solid #d8d6d6;
				border-radius: $global-radius;

				width: calc( 100% - 90px - 50px );
				height: 20px;

				margin: 0px 10px;

				overflow: hidden;

				.rating_level_bar_inner{
					background: linear-gradient(to bottom,$bar_color,lighten($bar_color, 2));
					background-color: $bar_color;
					height: 100%;

					@include play-animation( show-bar, 1s, false);

					transform: translateX(0%);
				}
			}

			.rating_level_percentage{
				width: 50px;
				text-align: right;
			}
		}
	}

	.rating_item_points {
		background-image: url('#{$url}');
		background-repeat: no-repeat;
		vertical-align: middle;
		width: 110px;
		height: 20px;
	}

	@each $star_level, $star_level_breakpoints in $stars{
		@each $value in $star_level_breakpoints{
			.rating_item_points.points-#{$value}{
				background-position: 0 map-get($points, $star_level);
			}
		}
	}
}