//********************************************************************//
//************************ BASICS AND MODULES ************************//
//********************************************************************//
$basepath_images: "/resources/images/";
$basepath_font: "/resources/fonts/";

$primary-color: #832555;
$secondary-color: #C65897;

$primary-color-dark: darken($primary-color, 20);
$primary-color-light:lighten($primary-color, 20);

$global-width: 1125px;

$height-header-small: 143px;
$height-header-medium: 143px;
$height-header-large: 143px;

$first-header-height: 40px;
$second-header-height: 105px;
$second-header-reduced-height: 65px;

$font_family_headline: 'CaeciliaLTPro', Times, sans-serif;
$font_family_text: Verdana , sans-serif;
$font_family_riegel_icons: 'riegel-icons-web-regular', sans-serif;

$rating_points: (
        1: 0,
        1_5: -21px,
        2: -42px,
        2_5: -64px,
        3: -84px,
        3_5: -106px,
        4: -126px,
        4_5: -146px,
        5: -168px
);
